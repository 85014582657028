<template>
  <!--xs: phone, sm: pad, md: pc, lg: 2k-pc, xl: 4k-pc-->
  <nav id="header">
    <!-- 顶部导航 -->
    <div class="ccontainer">
      <ul class="ccontainer-left-ul">
        <li>
          <img id="icon" src="../../public/icon.svg" alt="G" />
        </li>
        <li :class="($store.state.activeName == item.activeName) || 0 ? 'menu-item-active' : 'ccontainer-left-li'"
          @click="toActiveMenuItem(item)" v-for="(item, index) in leftMenuList" :key="index">
          {{ $t(item.titleName) }}
        </li>
      </ul>

      <ul class="ccontainer-right-ul">
        <li id="dark-light">
          <button @click="changeLang" title="en/zh" >
            <i v-if="en" class="fa-solid fa-dollar-sign"></i>
            <i v-else class="fa-solid fa-yen-sign"></i>

          </button>
        </li>
        <li id="dark-light">
          <button @click="changeTheme" title="Dark/Light" >
            <i v-if="dark" class="fa-regular fa-moon"></i>
            <i v-else class="fa-regular fa-sun"></i>
          </button>
        </li>
        <!--  <li :class="$store.state.activeName === item.activeName ? 'menu-item-active' -->
        <li :class="$store.state.activeName == item.activeName ? 'menu-item-active' : 'ccontainer-right-li'"
          @click="toActiveMenuItem(item)" v-for="(item, index) in rightMenuList" :key="index">
          {{ item.titleName }}
        </li>
        <li id="bars" @click="dropDownShow = !dropDownShow">
          <i class="fa-solid fa-ellipsis"></i>
        </li>
      </ul>
    </div>

    <!-- 下拉菜单 -->
    <transition name="dropdown-fade-show">
      <div v-show="dropDownShow" class="dropdown">
        <ul class="dropdown-top-ul">
          <li class="dropdown-top-li" v-for="(item, index) in leftMenuList" :key="index" @click="toActiveMenuItem(item)">
            {{ item.titleName }}</li>
        </ul>
        <ul class="dropdown-bottom-ul">
          <li class="dropdown-bottom-li" v-for="(item, index) in rightMenuList" :key="index"
            @click="toActiveMenuItem(item)">{{ item.titleName }}</li>
        </ul>
      </div>
    </transition>

  </nav>
</template>
<script>


export default {
  name: 'Header',
  mounted() {
    this.$store.state.activeName = this.$route.path.split("/")[1] //取出路径
  },
  data() {
    return {
      dark: true,
      en:true,
      dropDownShow: false, // 控制下拉菜单显示
      leftMenuList: [ // 左侧菜单内容
        { activeName: 'index', titleName: 'Index', activeUrl: '/index' },
        { activeName: 'blog', titleName: 'Blog', activeUrl: '/blog' },
        { activeName: 'bin', titleName: 'Bin', activeUrl: '/bin' },

      ],
      rightMenuList: [ // 右侧菜单内容
        // { activeName: 'login', titleName: 'Login', activeUrl: '/login' },
        { activeName: 'about', titleName: 'About', activeUrl: '/about' },

      ],
      activeName: '',// 导航栏激活名称,
    }
  },
  created() {
    let strr = localStorage.getItem('data-bs-theme');
    let theme = strr ? JSON.parse(strr) : 'dark'
    if (theme == 'dark') {
      this.dark = true
    } else {
      this.dark = false
    }
    document.documentElement.setAttribute('data-bs-theme', theme)


    strr = localStorage.getItem('lang');
    this.en = strr ? JSON.parse(strr) : true
    if(!this.en){
      this.$i18n.locale = "zh"
    }
  },
  computed: {
    isDark() {
      //pass
    }
  },
  methods: {
    changeLang(){
      let b=this.en

      if(b){
        this.en=false
        this.$i18n.locale = "zh"
        localStorage.setItem('lang', JSON.stringify(false))
      }else{
        this.en=true
        this.$i18n.locale = "en"
        localStorage.setItem('lang', JSON.stringify(true))
      }
      // this.$router.go(0)  //浏览器刷新
      window.location.reload()

    },
    changeTheme() {
      let b = this.dark;
      if (b) {
        this.changeLight()
      } else {
        this.changeDark()
      }
      this.dark = !this.dark;
      console.log(this.dark)
    },
    changeLight() {
      document.documentElement.setAttribute('data-bs-theme', 'light')
      localStorage.setItem('data-bs-theme', JSON.stringify('light'))
    },
    changeDark() {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
      localStorage.setItem('data-bs-theme', JSON.stringify('dark'))
    },
    toActiveMenuItem(item) { // 激活导航菜单
      this.$store.state.activeName = item.activeName
      this.$router.push(item.activeUrl)
      this.dropDownShow = false
    }
  }
}

</script>

<style lang="less" scoped>
/* Less 变量文件 */

// 配置页头的相关参数
@header-height: 50px;
@header-li-width: 70px;

// 导航栏菜单激活颜色
@menu-active-color: #6cf6ca;

// 页面样式配置
@headerBg: #19191c;
@headerTextColor: #ffffff;





#header {


  ul,
  li {
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  color: @headerTextColor;
  background: @headerBg;
  width: 100%;
  position: relative;
  z-index: 2000;
  box-sizing: border-box;

  .ccontainer {
    margin: 0 auto;
    padding: 0 22px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: @header-height; //把高度给这里，副元素默认撑开。当可折叠的关于战开始，父元素也会撑开
    box-sizing: border-box;



    .ccontainer-left-ul {
      li {
        height: 100%;
        line-height: @header-height;
        width: @header-li-width;
        display: inline-block;
      }

      #icon {
        height: 30px;
        vertical-align: middle;
        // transform: rotate(7deg);
        transition: transform 0.5s;

        &:hover {
          transform: scale(1.5, 1.5) ;
          // rotate(21deg);
        }
      }

      .ccontainer-left-li:hover {
        color: @menu-active-color;
        box-shadow: 0px -4px 0px @menu-active-color inset;
      }
    }

    .ccontainer-right-ul {

      li {
        height: 100%;
        line-height: @header-height;
        width: @header-li-width;
        display: inline-block;
      }

      #dark-light {

        width: 0.5*@header-li-width;

        >button {
          background-color: #19191c;
          border: 0;
          color: aliceblue;
          height: @header-height;

          >i {
            font-size: 20px;
            line-height: @header-height;
            vertical-align: middle;
          }
        }

      }

      .ccontainer-right-li:hover {
        color: @menu-active-color;
        box-shadow: 0px -4px 0px @menu-active-color inset;
      }

      #bars>i {
        padding: 8px 14px;
        // border: 1px @headerTextColor solid;
        border-radius: 5px;
      }
    }

  }

  .dropdown {
    //被折叠的部分

    width: 100%;
    background: @headerBg;


    li {
      height: 40px;
      line-height: 40px;

      &:hover {
        background: black;
      }
    }
  }

  & .menu-item-active {
    color: @menu-active-color;
    box-shadow: 0px -4px 0px @menu-active-color inset;

  }


  @media screen and (min-width: 1080px) {

    #bars {
      display: none;
    }

    .ccontainer {
      max-width: 1080px;
    }

  }

  @media screen and (min-width:720px) and (max-width: 1080px) {

    .ccontainer-left-li,
    .ccontainer-right-li {
      display: none !important;
    }

    .ccontainer {
      width: 100%;
      padding: 0 8%; //这个padding 来源于辅助居中样式
    }
  }

  @media screen and (max-width: 720px) {

    .ccontainer-left-li,
    .ccontainer-right-li {
      display: none !important;
    }
  }

}
</style>
