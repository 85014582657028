<template>
    <div class="bin"  style="padding: 5% 15%;">
        <h2>
            {{ $t("tbc") }}
            Any useful or useless garbage will be dumped here
            任何有用的或是无用的垃圾将会倾倒于此
        </h2>
    </div>

</template>

<script>
export default {
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>



<style lang="less" scoped>

</style>